import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
import { User } from '../_models/user';
import { AlertifyService } from './alertify.service';
import { LoadspecsService } from './loadspecs.service';
import { Menu } from '../_models/Menu';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = environment.apiUrl + 'auth/';
  jwtHelper = new JwtHelperService();
  decodedToken: any;
  menus: Menu[];
  roleid: any =  Number(localStorage.getItem('RoleID'));
  constructor(private http: HttpClient, private alertify: AlertifyService, private loadspecsService: LoadspecsService,
    private router: Router) { }

  login(model: any) {
    return this.http.post(this.baseUrl + 'login', model)
      .pipe(
        map((response: any) => {
          const user = response;
          if (user) {
            localStorage.setItem('token', user.token);
            localStorage.setItem('RoleID', user.user.userRoles[0].roleID);
            localStorage.setItem('UserId', user.user.id);
            this.decodedToken = this.jwtHelper.decodeToken(user.token);
            localStorage.setItem('LoginUser', user.user.username);
          }
        })
      );
  }

  register(request: User[]) {
    return this.http.post(this.baseUrl + 'register', request);
  }
  addinternaluser(request: User[]) {
    return this.http.post(this.baseUrl + 'addinternaluser', request);
  }
  registercompany(request) {
    return this.http.post(this.baseUrl + 'registercompany', request);
  }
  verifyemailPost(request: User[]) {
    return this.http.post(this.baseUrl + 'verifyemailpost', request);
  }
  resetusername(request: User[]) {
    return this.http.post(this.baseUrl + 'resetusername', request);
  }
  verifyotp(request: User[]) {
    return this.http.post(this.baseUrl + 'verifyotp', request);
  }
  resetpasspost(request: User[]) {
    return this.http.post(this.baseUrl + 'resetpasspost', request);
  }
  activateuser(request) {
    return this.http.put(this.baseUrl + 'activateuser', request);
  }
  resetpassotp(request: User[]) {
    return this.http.post(this.baseUrl + 'resetpassotp', request);
  }
  EdituserPost(request: User[]) {
    return this.http.put(this.baseUrl + 'resetpassword', request);
  }
  EdituserInfoPost(request: User[]) {
    return this.http.put(this.baseUrl + 'edituserinfopost', request);
  }

  loggedIn() {
    const token = localStorage.getItem('token');
    return !this.jwtHelper.isTokenExpired(token);
  }
  resetPassword(model: any) {
    return this.http.put(this.baseUrl + 'resetpassword', model);
  }
  checkAcess() {
    return  this.loadspecsService.getmenus(Number(localStorage.getItem('RoleID'))).subscribe((menus: Menu[]) => {
      this.menus = menus.filter(proj => (proj.url === this.router.url));
      // console.log(this.menus);
      // console.log(this.menus.length);
      // console.log(this.router.url);
      // console.log(this.router.url);
      // console.log(this.menus.length !== 1 && this.router.url !== '/dashboard');
      // console.log(this.menus.length !== 1 && this.router.url !== '/profile');
      if (this.menus.length !== 1 && this.router.url !== '/dashboard' && this.router.url !== '/'
            && this.router.url !== '/profile') {
        this.alertify.error('Access Denied');
        this.router.navigate(['/dashboard']);
      }
    }, error => {
      this.alertify.error(error);
    });
  }
}
