import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterCompanyComponent } from './views/register-company/register-company.component';
import { RegisterComponent } from './views/register/register.component';
import { AuthGuard } from './_guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'itad-register-company',
    component: RegisterCompanyComponent,
    data: {
      title: 'Register Company Page'
    }
  },
  {
    path: 'product-details/:id/:type',
    component: DefaultLayoutComponent,
    loadChildren: () => import('./views/product-details/product-details.module')
    .then(m => m.ProductDetailsModule)
  },
  {
    path: 'compare-stocks/:stockid',
    component: DefaultLayoutComponent,
    loadChildren: () => import('./views/compare-stocks/compare-stocks.module')
    .then(m => m.CompareStocksModule)
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Dashboard',
    },
    children: [
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'roles',
        loadChildren: () => import('./views/role/role.module').then(m => m.RoleModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule),
      },
      {
        path: 'managehdd',
        loadChildren: () => import('./views/manage-hdd/manage-hdd.module').then(m => m.ManageHddModule),
      },
      {
        path: 'manageram',
        loadChildren: () => import('./views/manage-ram/manage-ram.module').then(m => m.ManageRamModule),
      },
      {
        path: 'managemakemodel',
        loadChildren: () => import('./views/make-model/make-model.module').then(m => m.MakeModelModule),
      },
      {
        path: 'manage-supplier',
        loadChildren: () => import('./views/manage-supplier/manage-supplier.module').then(m => m.ManageSupplierModule),
      },
      {
        path: 'managemenu',
        loadChildren: () => import('./views/menu/menu.module').then(m => m.MenuModule),
      },
      {
        path: 'chat-setting',
        loadChildren: () => import('./views/chat-setting/chat-setting.module').then(m => m.ChatSettingModule),
      },
      {
        path: 'manage_itad_expiration',
        loadChildren: () => import('./views/itad-expiration/itad-expiration.module').then(m => m.ItadExpirationModule),
      },
      {
        path: 'stocks',
        loadChildren: () => import('./views/stocks/stocks.module').then(m => m.StocksModule)
      },
      {
        path: 'requirements',
        loadChildren: () => import('./views/requirements/requirements.module').then(m => m.RequirementsModule)
      },
      {
        path: 'itad_available',
        loadChildren: () => import('./views/itda-available/itda-available.module').then(m => m.ItdaAvailableModule)
      },
      {
        path: 'circular_requirements',
        loadChildren: () => import('./views/itda-requirements/itda-requirements.module').then(m => m.ItdaRequirementsModule)
      },
      {
        path: 'itad_Suppliers',
        loadChildren: () => import('./views/itda-suppliers/itda-suppliers.module').then(m => m.ItdaSuppliersModule)
      },
      {
        path: 'itad_company_list',
        loadChildren: () => import('./views/itad-company-list/itad-company-list.module').then(m => m.ItadCompanyListModule)
      },
      {
        path: 'itad_chat_setting',
        loadChildren: () => import('./views/itad-chat-setting/itad-chat-setting.module').then(m => m.ItadChatSettingModule)
      },
      {
        path: 'laptop-data-correction',
        loadChildren: () => import('./views/laptop-data-correction/laptop-data-correction.module').then(m => m.LaptopDataCorrectionModule)
      },
      {
        path: 'desktop-data-correction',
        loadChildren: () => import('./views/desktop-data-correction/desktop-data-correction.module')
        .then(m => m.DesktopDataCorrectionModule)
      },
      {
        path: 'monitor-data-correction',
        loadChildren: () => import('./views/monitor-data-correction/monitor-data-correction.module')
        .then(m => m.MonitorDataCorrectionModule)
      },
      {
        path: 'approvedstock',
        loadChildren: () => import('./views/approved-stocks/approved-stocks.module').then(m => m.ApprovedStocksModule)
      },
      {
        path: 'shareddeals',
        loadChildren: () => import('./views/share-deals/share-deals.module').then(m => m.ShareDealsModule)
      },
      {
        path: 'salesteam',
        loadChildren: () => import('./views/purchase-users/purchase-users.module').then(m => m.PurchaseUsersModule)
      },
      {
        path: 'orders',
        loadChildren: () => import('./views/reserve-order/reserve-order.module').then(m => m.ReserveOrderModule)
      },
      {
        path: 'purchase-orders',
        loadChildren: () => import('./views/purchase-order/purchase-order.module').then(m => m.PurchaseOrderModule)
      },
      {
        path: 'commit-purchase',
        loadChildren: () => import('./views/commit-purchase/commit-purchase.module').then(m => m.CommitPurchaseModule)
      },
      {
        path: 'commit-order',
        loadChildren: () => import('./views/commit-order/commit-order.module').then(m => m.CommitOrderModule)
      },
      {
        path: 'shortfall',
        loadChildren: () => import('./views/short-fall/short-fall.module').then(m => m.ShortFallModule)
      },
      {
        path: 'short-fall-refurb',
        loadChildren: () => import('./views/short-fall-refurb/short-fall-refurb.module').then(m => m.ShortFallRefurbModule)
      },
      {
        path: 'short-fall-details',
        loadChildren: () => import('./views/short-fall-details/short-fall-details.module').then(m => m.ShortFallDetailsModule)
      },
      {
        path: 'commit-shortFall',
        loadChildren: () => import('./views/commit-shortfall/commit-shortfall.module').then(m => m.CommitShortfallModule)
      },
      {
        path: 'closeddeal',
        loadChildren: () => import('./views/close-deal/close-deal.module').then(m => m.CloseDealModule)
      },
      {
        path: 'removeddeal',
        loadChildren: () => import('./views/remove-deal/remove-deal.module').then(m => m.RemoveDealModule)
      },
      {
        path: 'rejecteddeal',
        loadChildren: () => import('./views/reject-deal/reject-deal.module').then(m => m.RejectDealModule)
      },
      {
        path: 'add-price',
        loadChildren: () => import('./views/add-price/add-price.module').then(m => m.AddPriceModule)
      },
      {
        path: 'check-price',
        loadChildren: () => import('./views/check-price/check-price.module').then(m => m.CheckPriceModule)
      },
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
