import { Component, OnInit } from '@angular/core';
import { MyLocation } from '../../_models/MyLocation';
import { AlertifyService } from '../../_services/alertify.service';
import { AuthService } from '../../_services/auth.service';
import { LoadspecsService } from '../../_services/loadspecs.service';

@Component({
  selector: 'app-register-company',
  templateUrl: './register-company.component.html',
  styleUrls: ['./register-company.component.css']
})
export class RegisterCompanyComponent implements OnInit {
  mymodel: any = {};
  submitmodel: any = {};
  registercheck: any = 0;
  locations: MyLocation[];

  constructor(private loadspecsService: LoadspecsService, private alertify: AlertifyService, private authService: AuthService) { }

  ngOnInit(): void {
    this.mymodel.country_id = '0';
    this.loadLocations();
  }
  loadLocations() {
    return this.loadspecsService.loadLocations().subscribe((locations: MyLocation[]) => {
      this.locations = locations.filter(proj => (proj.status === 1));
    }, error => {
      this.alertify.error(error);
    });
  }
  ApplyNow() {
    if (!this.mymodel.com_name) {
      this.alertify.error('Enter company name');
      return;
    }
    if (this.mymodel.com_name.trim() === '') {
      this.alertify.error('Enter valid company name');
      return;
    }
    if (!this.mymodel.com_tel) {
      this.alertify.error('Enter telephone');
      return;
    }
    // if (Number((this.mymodel.com_tel).toString().length) < 7) {
    //   this.alertify.error('Invalid number, telephone must be minimum 7 digits');
    //   return;
    // }
    if (!this.mymodel.com_domain) {
      this.alertify.error('Enter company domain');
      return;
    }
    if (!(this.mymodel.com_domain.indexOf('http://') > -1) && !(this.mymodel.com_domain.indexOf('https://') > -1)) {
      this.mymodel.com_domain = 'https://' + this.mymodel.com_domain;
    }
    if (this.mymodel.com_domain.trim() === '') {
      this.alertify.error('Enter valid company domain');
      return;
    }
    if (!this.mymodel.com_street) {
      this.alertify.error('Enter street');
      return;
    }
    if (this.mymodel.com_street.trim() === '') {
      this.alertify.error('Enter valid street');
      return;
    }
    if (!this.mymodel.com_city) {
      this.alertify.error('Enter city');
      return;
    }
    if (this.mymodel.com_city.trim() === '') {
      this.alertify.error('Enter valid city');
      return;
    }
    if (this.mymodel.country_id === '0') {
      this.alertify.error('Select country');
      return;
    }
    if (!this.mymodel.branch_num) {
      this.alertify.error('Enter number of company locations');
      return;
    }
    if (!this.mymodel.laptop_num) {
      this.alertify.error('Enter  number of laptop assets handled PA');
      return;
    }
    if (!this.mymodel.desk_num) {
      this.alertify.error('Enter  number of desktop assets handled PA');
      return;
    }
    if (!this.mymodel.monitor_num) {
      this.alertify.error('Enter  number of monitor assets handled PA');
      return;
    }
    if (!this.mymodel.div_revenue) {
      this.alertify.error('Enter division revenue');
      return;
    }
    if (!this.mymodel.div_revenue.trim()) {
      this.alertify.error('Enter division revenue');
      return;
    }
    if (!this.mymodel.staff_num) {
      this.alertify.error('Enter number of staff');
      return;
    }
    if (!this.mymodel.con_name) {
      this.alertify.error('Enter contact name');
      return;
    }
    if (this.mymodel.con_name.trim() === '') {
      this.alertify.error('Enter valid contact name');
      return;
    }
    if (!this.mymodel.con_email) {
      this.alertify.error('Enter email');
      return;
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.mymodel.con_email.trim())) {
      this.alertify.error('Enter valid email');
      return;
    }
    if (!this.mymodel.con_tel) {
      this.alertify.error('Enter main contact landline telephone');
      return;
    }
    // if (Number((this.mymodel.con_tel).toString().length) < 7) {
    //   this.alertify.error('Invalid number, landline telephone must be minimum 7 digits');
    //   return;
    // }
    if (!this.mymodel.con_mob) {
      this.alertify.error('Enter contact mobile');
      return;
    }
    // if (Number((this.mymodel.con_mob).toString().length) < 10) {
    //   this.alertify.error('Invalid number, mobile must be minimum 10 digits');
    //   return;
    // }
    // if (!this.mymodel.mar_con_tel) {
    //   if (Number((this.mymodel.mar_con_tel).toString().length) < 7) {
    //     this.alertify.error('Invalid number, marketing telephone must be minimum 7 digits');
    //     return;
    //   }
    // }
    if (this.mymodel.mar_con_email) {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.mymodel.mar_con_email)) {
        this.alertify.error('Enter valid marketing contact email');
        return;
      }
    }
    this.submitmodel.com_name = this.mymodel.com_name.trim();
    this.submitmodel.com_tel = this.mymodel.com_tel;
    this.submitmodel.com_domain = this.mymodel.com_domain.trim();
    this.submitmodel.com_street = this.mymodel.com_street.trim();
    this.submitmodel.com_city = this.mymodel.com_city.trim();
    this.submitmodel.country_id = this.mymodel.country_id;
    this.submitmodel.branch_num = this.mymodel.branch_num;
    this.submitmodel.laptop_num = this.mymodel.laptop_num;
    this.submitmodel.desk_num = this.mymodel.desk_num;
    this.submitmodel.monitor_num = this.mymodel.monitor_num;
    this.submitmodel.div_revenue = this.mymodel.div_revenue;
    this.submitmodel.staff_num = this.mymodel.staff_num;
    this.submitmodel.con_name = this.mymodel.con_name.trim();
    this.submitmodel.con_email = this.mymodel.con_email.trim();
    this.submitmodel.con_tel = this.mymodel.con_tel;
    this.submitmodel.con_mob = this.mymodel.con_mob;
    this.submitmodel.mar_con_name = this.mymodel.mar_con_name;
    this.submitmodel.mar_con_tel = this.mymodel.mar_con_tel;
    this.submitmodel.mar_con_email = this.mymodel.mar_con_email;
    this.submitmodel.comments = this.mymodel.comments;
    document.getElementById('ReqAcc').innerHTML = '<i class="fa fa-circle-o-notch mt-1 fa-lg fa-spin"></i> Processing...';
    (document.getElementById('ReqAcc') as HTMLButtonElement).disabled = true;
    this.authService.registercompany(this.submitmodel).subscribe(() => {
      document.getElementById('ReqAcc').innerHTML = 'Submit';
      (document.getElementById('ReqAcc') as HTMLButtonElement).disabled = false;
      this.registercheck = 1;
    }, error => {
      this.alertify.error(error.error);
      document.getElementById('ReqAcc').innerHTML = 'Submit';
      (document.getElementById('ReqAcc') as HTMLButtonElement).disabled = false;
    });
  }
  omit_special_char(event: { charCode: any; }) {
    let k: number;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return(k === 8 || (k >= 48 && k <= 57));
  }

}
