import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Processor } from '../_models/Processor';
import { PartParoduct } from '../_models/PartParoduct';
import { SpectDetail } from '../_models/SpectDetail';
import { MyLocation } from '../_models/MyLocation';
import { PurchaseUser } from '../_models/PurchaseUser';
import { Generation } from '../_models/Generation';
import { Menu } from '../_models/Menu';
import { Supplier } from '../_models/Supplier';
import { Page } from '../_models/Page';
import { ActiveModel } from '../_models/ActiveModel';
import { ActiveProcessor } from '../_models/ActiveProcessor';
import { ChatSetting } from '../_models/ChatSetting';
import { EmailSetting } from '../_models/EmailSetting';
import { ITADSupliers } from '../_models/ITADSupliers';
import { Currency } from '../_models/Currency';
import { ITADCompanyList } from '../_models/ITADCompanyList';
import { UserType } from '../_models/UserType';
import { TopSup } from '../_models/TopSup';
import { TopModel } from '../_models/TopModel';
import { ComparedData } from '../_models/ComparedData';
import { SupplierQty } from '../_models/SupplierQty';
import { SupplierModelQty } from '../_models/SupplierModelQty';

@Injectable({
  providedIn: 'root'
})
export class LoadspecsService {
  baseUrl = environment.apiUrl + 'values/';

  constructor( private http: HttpClient) { }

  getMakes(): Observable<PartParoduct[]> {
    return this.http.get<PartParoduct[]>(this.baseUrl + 'getmakes');
  }
  getmenus(roleid): Observable<Menu[]> {
    return this.http.get<Menu[]>(this.baseUrl + 'getmenus/' + roleid);
  }
  getallmenus(): Observable<Menu[]> {
    return this.http.get<Menu[]>(this.baseUrl + 'getallmenus');
  }
  getemailsetting(): Observable<EmailSetting[]> {
    return this.http.get<EmailSetting[]>(this.baseUrl + 'getemailsetting');
  }
  getChatSetting(): Observable<ChatSetting[]> {
    return this.http.get<ChatSetting[]>(this.baseUrl + 'getchatsetting');
  }
  loadActiveModels(type): Observable<ActiveModel[]> {
    return this.http.get<ActiveModel[]>(this.baseUrl + 'getactivatemodel/' + Number(localStorage.getItem('UserId')) + '/' + type);
  }
  loadActiveProc(type): Observable<ActiveProcessor[]> {
    return this.http.get<ActiveProcessor[]>(this.baseUrl + 'getactivateproc/' + Number(localStorage.getItem('UserId')) + '/' + type);
  }
  getcurrency(): Observable<Currency[]> {
    return this.http.get<Currency[]>(this.baseUrl + 'getcurrency');
  }
  getallpages(): Observable<Page[]> {
    return this.http.get<Page[]>(this.baseUrl + 'getallpages');
  }
  getGenerations(): Observable<Generation[]> {
    return this.http.get<Generation[]>(this.baseUrl + 'getgenerations');
  }
  loadLocations(): Observable<MyLocation[]> {
    return this.http.get<MyLocation[]>(this.baseUrl + 'loadlocations');
  }
  loadSpectDetails(): Observable<SpectDetail[]> {
    return this.http.get<SpectDetail[]>(this.baseUrl + 'getspecs');
  }
  loadExpDays(): Observable<UserType[]> {
    return this.http.get<UserType[]>(this.baseUrl + 'getexpdays');
  }
  loadSuppliers(): Observable<Supplier[]> {
    return this.http.get<Supplier[]>(this.baseUrl + 'getsupplier');
  }
  getModels(): Observable<PartParoduct[]> {
    return this.http.get<PartParoduct[]>(this.baseUrl + 'getmodels');
  }
  getProcessors(): Observable<Processor[]> {
    return this.http.get<Processor[]>(this.baseUrl + 'getprocessors');
  }
  Addspecs(request: SpectDetail) {
    return this.http.post(this.baseUrl + 'addspecs', request);
  }
  AddExpDays(request: UserType) {
    return this.http.post(this.baseUrl + 'addexpdays', request);
  }
  EditExpDays(request: UserType) {
    return this.http.post(this.baseUrl + 'editexpdays', request);
  }
  AddMake(request: PartParoduct) {
    return this.http.post(this.baseUrl + 'addmake', request);
  }
  AddModel(request: PartParoduct) {
    return this.http.post(this.baseUrl + 'addmodel', request);
  }
  Editspecs(request: SpectDetail) {
    return this.http.post(this.baseUrl + 'editspecs', request);
  }
  getPurchaseUsers(): Observable<PurchaseUser[]> {
    return this.http.get<PurchaseUser[]>(this.baseUrl + 'getpurchaseusers');
  }
  getMyPurchaseUsers(): Observable<PurchaseUser[]> {
    return this.http.get<PurchaseUser[]>(this.baseUrl + 'getpurchaseusers1');
  }
  getSupUsers(): Observable<PurchaseUser[]> {
    return this.http.get<PurchaseUser[]>(this.baseUrl + 'getsupusers');
  }
  AddPurchaseUsers(request: PurchaseUser) {
    return this.http.post(this.baseUrl + 'addpurchaseusers', request);
  }
  AddSupplier(request) {
    return this.http.post(this.baseUrl + 'addsupplier', request);
  }
  EditPurchaseUserPost(request: PurchaseUser) {
    return this.http.put(this.baseUrl + 'editpurchaseusers', request);
  }
  DeletePurchaseUsers(request: PurchaseUser) {
    return this.http.put(this.baseUrl + 'deletepurchaseusers', request);
  }
  activateMenu(request) {
    return this.http.put(this.baseUrl + 'activatemenu', request);
  }
  activateEmail(request) {
    return this.http.put(this.baseUrl + 'activateemail', request);
  }
  activateChat(request) {
    return this.http.put(this.baseUrl + 'activatechat', request);
  }
  getITADSuppliers(): Observable<ITADSupliers[]> {
    return this.http.get<ITADSupliers[]>(this.baseUrl + 'getitadsupliers');
  }
  getITADCompanyList(): Observable<ITADCompanyList[]> {
    return this.http.get<ITADCompanyList[]>(this.baseUrl + 'getitadcompanylist');
  }
  ApproveCompany(request) {
    return this.http.put(this.baseUrl + 'approvecompany', request);
  }
  RejectCompany(request) {
    return this.http.put(this.baseUrl + 'rejectcompany', request);
  }
  GetCompareData(stockid) {
    return this.http.get(this.baseUrl + 'getcomparedstock/' + stockid);
  }
  PurchaseStock(stockid, userid, p_date) {
    return this.http.get<ActiveModel[]>(this.baseUrl + 'purchasestock/' + stockid + '/' + userid + '/' + p_date);
  }
  gettopsup(s_date, e_date): Observable<TopSup[]> {
    return this.http.get<TopSup[]>(this.baseUrl + 'gettopsup/' + s_date + '/' + e_date);
  }
  gettopmodel(s_date, e_date): Observable<TopModel[]> {
    return this.http.get<TopModel[]>(this.baseUrl + 'gettopmodel/' + s_date + '/' + e_date);
  }
  getsupplierwiseqty(s_date, e_date): Observable<SupplierQty[]> {
    return this.http.get<SupplierQty[]>(this.baseUrl + 'supplierwiseqty/' + s_date + '/' + e_date);
  }
  getsupplierandmodelwiseqty(s_date, e_date): Observable<SupplierModelQty[]> {
    return this.http.get<SupplierModelQty[]>(this.baseUrl + 'suppliermodelwiseqty/' + s_date + '/' + e_date);
  }
  UploadExcel(request, type) {
    if (type === 'laptop') {
      return this.http.post(this.baseUrl + 'InsertLaptopUpload', request);
    }
    if (type === 'desktop') {
      return this.http.post(this.baseUrl + 'InsertDesktopUpload', request);
    }
    if (type === 'monitor') {
      return this.http.post(this.baseUrl + 'InsertMonitorUpload', request);
    }
  }
  PurchaseAllStock(request): Observable<ComparedData[]> {
    return this.http.post<ComparedData[]>(this.baseUrl + 'PurchaseAllStock', request);
  }
}
