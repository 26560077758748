import {Component, OnInit} from '@angular/core';
import { INavData } from '@coreui/angular';
import { Router } from '@angular/router';
import { AlertifyService } from '../../_services/alertify.service';
import { AuthService } from '../../_services/auth.service';
import { LoadspecsService } from '../../_services/loadspecs.service';
import { Menu } from '../../_models/Menu';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent  implements OnInit {
  public sidebarMinimized = false;
  public routerlinkactive = false;
  navItems: INavData[];
  menus: Menu[];
  roleid: any =  Number(localStorage.getItem('RoleID'));
  username: any =  String(localStorage.getItem('LoginUser'));

  constructor(public authService: AuthService, private alertify: AlertifyService,
              private router: Router, private loadspecsService: LoadspecsService ) { }


  ngOnInit() {
    const x =  this.loadspecsService.getmenus(this.roleid).subscribe((menus: Menu[]) => {
      this.menus = menus;
    function list_to_tree(list) {
      const map = {}, roots = [];
      for (let i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
      }
      for (let i = 0; i < list.length; i += 1) {
        const node = list[i];
        if (node.parent_id !== 0) {
          // if you have dangling branches check that map[node.parentId] exists
          list[map[node.parent_id]].children.push(node);
        } else {
          roots.push(node);
        }
      }
      return roots;
    }

      this.navItems = [
        {
          name: 'Dashboard',
          url: '/dashboard',
          icon: 'icon-speedometer'
        },
        {
          title: true,
          name: 'Menu'
        },
      ];

      const myres = list_to_tree(menus);
      myres.forEach(function (value) {
        if (value.children.length === 0) {
          this.navItems.push({name: value.name, url: value.url, icon: value.icon});
        }
        if (value.children.length > 0) {
          const stocks = new Array();
          const myres1 = value.children;
          for (let i = 0; i < myres1.length; i += 1) {
            if (myres1[i].children.length === 0) {
              const obj = {name: myres1[i].name, url: myres1[i].url, icon: myres1[i].icon};
              stocks.push(obj);
            }
            if (myres1[i].children.length > 0) {
              const stocks1 = new Array();
              const myres2 = myres1[i].children;
              for (let j = 0; j < myres2.length; j += 1) {
                const obj1 = {name: myres2[j].name, url: myres2[j].url, icon: myres2[j].icon};
                stocks1.push(obj1);
              }
              const obj = {name: myres1[i].name, url: myres1[i].url, icon: myres1[i].icon, class: 'open1', children: stocks1};
              stocks.push(obj);
            }
          }
          this.navItems.push({name: value.name, url: value.url, icon: value.icon, class: 'open2', children: stocks});
        }
      }.bind(this));
    }, error => {
      this.alertify.error(error);
    });

    // if (this.roleid === 1 || this.roleid === 2 || this.roleid === 3 || this.roleid === 4 ) {
    //   this.navItems.push({name: 'Laptop Required', url: '/requirements', icon: 'icon-briefcase'});
    //   this.navItems.push({name: 'Laptop Available', url: '/stocks', icon: 'icon-basket-loaded'});

    // }
    // if (this.roleid !== 5) {
    //   this.navItems.push({name: 'Approved Deals', url: '/approvedstock', icon: 'icon-basket-loaded'});

    // }
    // if (this.roleid === 1 || this.roleid === 3 || this.roleid === 5) {
    //   this.navItems.push({name: 'Shared Deals', url: '/shareddeals', icon: 'icon-basket-loaded'});

    // }
    // if (this.roleid === 1 || this.roleid === 3) {
    //   this.navItems.push({name: 'Sales Team', url: '/salesteam', icon: 'icon-user'});

    // }
    // if (this.roleid === 1) {
    //   this.navItems.push({
    //     name: 'Master',
    //     url: '',
    //     icon: 'icon-settings',
    //     children: [
    //       {
    //         name: 'Role',
    //         url: '/roles',
    //         icon: 'icon-basket-loasded'
    //       },
    //       {
    //         name: 'Users',
    //         url: '/users',
    //         icon: 'icon-basket-loasded'
    //       },
    //       {
    //         name: 'Manage HDD',
    //         url: '/managehdd',
    //         icon: 'icon-basket-loasded'
    //       },
    //       {
    //         name: 'Manage RAM',
    //         url: '/manageram',
    //         icon: 'icon-basket-loasded'
    //       },
    //       {
    //         name: 'Make Model',
    //         url: '/managemakemodel',
    //         icon: 'icon-basket-loasded'
    //       }
    //     ]
    //   });

    // }
  }
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('RoleID');
    localStorage.removeItem('UserId');
    localStorage.removeItem('LoginUser');
    this.alertify.message('Logged out');
    this.router.navigate(['/login']);
 }
}
