import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { User } from '../../_models/user';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    forgotpass = false;
    forgotuser = false;
    registercheck: any = 0;
    msgflag = false;
    otpflag = false;
    resetpassflag = false;
    message: any = {};
    values: any;
    model: any = {};
    resetmodel: any = {};
    otpmodel: any = {};
    baseUrl = environment.apiUrl + 'values/';
    constructor(private http: HttpClient, public authService: AuthService, private alertify: AlertifyService,
    private router: Router ) { }

    ngOnInit(): void {
      if (this.authService.loggedIn()) {
        this.alertify.success('You are already loggedIn');
        this.router.navigate(['/dashboard']);
      }
    }
  login() {
    if (!this.model.username.trim()) {
      this.alertify.error('Invalid username');
      return;
    }
    if (!this.model.password.trim()) {
      this.alertify.error('Invalid password');
      return;
    }
    this.authService.login(this.model).subscribe(next => {
      this.alertify.success('Logged in successfully');
    }, error => {
      if (error.error.Password !== undefined) {
        this.alertify.error(error.error.Password[0]);
      } else {
        this.alertify.error(error.error);
      }
    }, () => {
      if (localStorage.getItem('currentUrl') === 'null') {
        if (Number(localStorage.getItem('RoleID'))  === 12 ) {
          this.router.navigate(['/itad_available']);
        } else {
          this.router.navigate(['/dashboard']);
        }
      } else {
        if (Number(localStorage.getItem('RoleID'))  === 12 ) {
          this.router.navigate(['/itad_available']);
          localStorage.setItem('currentUrl', null);
        } else {
          document.location.href = localStorage.getItem('currentUrl');
          localStorage.setItem('currentUrl', null);
        }
      }
    });
  }

  loggedIn() {
    return this.authService.loggedIn();
  }
  forgotpassword() {
    this.forgotpass = true;
    this.forgotuser = false;
    this.msgflag = false;
    this.otpflag = false;
    this.otpflag = false;
    this.resetpassflag = false;
  }
  resetusername() {
    this.forgotpass = false;
    this.forgotuser = true;
    this.msgflag = false;
    this.otpflag = false;
    this.resetpassflag = false;
  }
  loginback() {
    this.forgotpass = false;
    this.forgotuser = false;
    this.msgflag = false;
    this.otpflag = false;
    this.resetpassflag = false;
  }
  ResetUsernamePost() {
    if (!this.resetmodel.email.trim()) {
      this.alertify.error('Enter email');
      return;
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.resetmodel.email)) {
      this.alertify.error('Enter valid email');
      return;
    }
    document.getElementById('GetUsername').innerHTML = 'Sending..';
    (document.getElementById('GetUsername') as HTMLButtonElement).disabled = true;
    this.authService.resetusername(this.resetmodel).subscribe((users: User[]) => {
      document.getElementById('GetUsername').innerHTML = 'Get Username';
      (document.getElementById('GetUsername') as HTMLButtonElement).disabled = false;
        this.forgotpass = false;
        this.forgotuser = false;
        this.otpflag = false;
        this.resetpassflag = false;
        this.msgflag = true;
        this.message = 'Username successfully has been sent on ' + this.resetmodel.email;
    }, error => {
      if (error.error.Password !== undefined) {
        this.alertify.error(error.error.Password[0]);
      } else {
        this.alertify.error(error.error);
      }
      document.getElementById('GetUsername').innerHTML = 'Get Username';
      (document.getElementById('GetUsername') as HTMLButtonElement).disabled = false;
    });
  }
  ResetPasswordPost() {
    if (!this.resetmodel.email) {
      this.alertify.error('Enter email');
      return;
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.resetmodel.email.trim())) {
      this.alertify.error('Enter valid email');
      return;
    }
    this.authService.resetpassotp(this.resetmodel).subscribe((users: User[]) => {
        this.forgotpass = false;
        this.msgflag = false;
        this.otpflag = true;
        this.forgotuser = false;
        this.resetpassflag = false;
        this.message = 'OTP successfully has been sent on ' + this.resetmodel.email;
    }, error => {
      if (error.error.Password !== undefined) {
        this.alertify.error(error.error.Password[0]);
      } else {
        this.alertify.error(error.error);
      }
    });
  }

  VerifyOTP() {
    if (!this.otpmodel.otp) {
      this.alertify.error('Enter OTP');
      return;
    }
    if (Number((this.otpmodel.otp).toString().length) !== 4) {
      this.alertify.error('Invalid OTP');
      return;
    }
    this.otpmodel.email = this.resetmodel.email;
    this.authService.verifyotp(this.otpmodel).subscribe((users: User[]) => {
        this.otpflag = false;
        this.resetpassflag = true;
        this.otpmodel = {};
        this.alertify.success('Verified');
    }, error => {
      if (error.error.Password !== undefined) {
        this.alertify.error(error.error.Password[0]);
      } else {
        this.alertify.error(error.error);
      }
    });
  }
  ResetPassPost() {
    if (!this.resetmodel.Password) {
      this.alertify.error('Enter password');
      return;
    }
    if (!this.resetmodel.Repeat_password) {
      this.alertify.error('Enter repeat password');
      return;
    }
    if (this.resetmodel.Password !== this.resetmodel.Repeat_password) {
      this.alertify.error('Password does not match');
      return;
    }
    this.resetmodel.Username = 'Dummy';
    this.authService.resetpasspost(this.resetmodel).subscribe((users: User[]) => {
      this.resetpassflag = false;
      this.msgflag = true;
      this.message = 'Password successfully updated';
    }, error => {
      if (error.error.Password !== undefined) {
        this.alertify.error(error.error.Password[0]);
      } else {
        this.alertify.error(error.error);
      }
    });
  }
  omit_special_char(event: { charCode: any; }) {
    let k: number;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return(k === 8 || (k >= 48 && k <= 57));
  }

 }
