import { Component, OnInit, ViewChild, EventEmitter, ElementRef, Renderer2 } from '@angular/core';
import { MyLocation } from '../../_models/MyLocation';
import { User } from '../../_models/user';
import { AlertifyService } from '../../_services/alertify.service';
import { AuthService } from '../../_services/auth.service';
import { LoadspecsService } from '../../_services/loadspecs.service';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit  {
  mymodel: any = {};
  user: User;
  registercheck: any = 0;
  locations: MyLocation[];

  // emailexist: any = ['gmail', 'googlemail', 'protonmail', 'protonmail', 'outlook',
  // 'hotmail', 'yahoo', 'zoho', 'aim', 'aol', 'gmx', 'gmx', 'icloud', 'yandex'];

  emailexist: any = [''];

  constructor(private loadspecsService: LoadspecsService, private userService: UserService,
    private alertify: AlertifyService, private authService: AuthService) { }

    ngOnInit(): void {
      this.mymodel.Country = '0';
      this.loadLocations();
    }
    loadLocations() {
      return this.loadspecsService.loadLocations().subscribe((locations: MyLocation[]) => {
        this.locations = locations.filter(proj => (proj.status === 1));
      }, error => {
        this.alertify.error(error);
      });
    }

    Reqister() {
      if (!this.mymodel.FirstName) {
        this.alertify.error('Enter First Name');
        return;
      }
      if (this.mymodel.FirstName.trim() === '') {
        this.alertify.error('Enter First Name');
        return;
      }
      if (!this.mymodel.LastName) {
        this.alertify.error('Enter Last Name');
        return;
      }
      if (this.mymodel.LastName.trim() === '') {
        this.alertify.error('Enter Last Name');
        return;
      }
      if (!this.mymodel.Username) {
        this.alertify.error('Enter username');
        return;
      }
      if (this.mymodel.Username.trim() === '') {
        this.alertify.error('Enter username');
        return;
      }
      if (!this.mymodel.Email) {
        this.alertify.error('Enter email');
        return;
      }
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.mymodel.Email.trim())) {
        this.alertify.error('Enter valid email');
        return;
      }
      const splitted = this.mymodel.Email.split('@', 3);
      const splitted1 = splitted[1].split('.', 3);
      // console.log(splitted1[0]);
      // console.log(this.emailexist);
      if (this.emailexist.indexOf(splitted1[0]) > -1) {
        this.alertify.error('Enter your official email address');
        return;
      }
      if (!this.mymodel.mobile_no) {
        this.alertify.error('Enter mobile');
        return;
      }
      if (!this.mymodel.Company) {
        this.alertify.error('Enter company');
        return;
      }
      if (this.mymodel.Company.trim() === '') {
        this.alertify.error('Enter valid company');
        return;
      }
      // if (Number((this.mymodel.mobile_no).toString().length) < 10) {
      //   this.alertify.error('Invalid number, mobile must be minimum 10 digits');
      //   return;
      // }
      if (!this.mymodel.City) {
        this.alertify.error('Enter city');
        return;
      }
      if (this.mymodel.City.trim() === '') {
        this.alertify.error('Enter valid city');
        return;
      }
      if (this.mymodel.Country === '0') {
        this.alertify.error('Select country');
        return;
      }
      if (!this.mymodel.Password) {
        this.alertify.error('Enter password');
        return;
      }
      if (!this.mymodel.Password.trim()) {
        this.alertify.error('Invalid password');
        return;
      }
      if (!this.mymodel.Repeat_password) {
        this.alertify.error('Enter repeat password');
        return;
      }
      if (this.mymodel.Password !== this.mymodel.Repeat_password) {
        this.alertify.error('Password does not match');
        return;
      }

      this.mymodel.active = 1;
      // const splitted = this.mymodel.Email.split('@', 3);
      // if (this.emailexist.indexOf(splitted[1]) > -1) {
      //   this.mymodel.active = 0;
      // }

      this.mymodel.roleID = 12;
      this.mymodel.email_ext = splitted[1];
      this.mymodel.Username = this.mymodel.Username.trim();
      this.mymodel.Email = this.mymodel.Email.trim();
      this.mymodel.Company = this.mymodel.Company.trim();
      this.mymodel.City = this.mymodel.City.trim();
      document.getElementById('ReqAcc').innerHTML = '<i class="fa fa-circle-o-notch mt-1 fa-lg fa-spin"></i> Processing...';
      (document.getElementById('ReqAcc') as HTMLButtonElement).disabled = true;
      this.authService.register(this.mymodel).subscribe((users: User[]) => {
        document.getElementById('ReqAcc').innerHTML = 'Create Account';
        (document.getElementById('ReqAcc') as HTMLButtonElement).disabled = false;
        this.registercheck = 1;
      }, error => {
        if (error.error.Password !== undefined) {
          this.alertify.error(error.error.Password[0]);
        } else {
          this.alertify.error(error.error);
        }
        document.getElementById('ReqAcc').innerHTML = 'Create Account';
        (document.getElementById('ReqAcc') as HTMLButtonElement).disabled = false;
      });
    }
    omit_special_char(event: { charCode: any; }) {
      let k: number;
      k = event.charCode;  //         k = event.keyCode;  (Both can be used)
      return(k === 8 || (k >= 48 && k <= 57));
    }

}
